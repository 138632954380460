import * as React from 'react'
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PageWrapper from '../components/PageWrapper'
// import Seo from "../components/Seo"
import Seo2 from '../components/Seo2'
import RecordsFeed from '../components/RecordsFeed'
import { PageTitle } from '../components/PageTitles'
import YearFilters from '../components/YearFilters'
import Pagination from '../components/Pagination'


const YearTemplate = ({ data, pageContext, location }) => {

    const theSlug = location.pathname
    const secondPath = theSlug.split('/')[2]
    
    // const metaTitle = `${secondPath} Releases`;

    // const recordMetaDesc = data.wp.seo.contentTypes.release.metaDesc;

    // const [buttonText, setButtonText] = React.useState(" ");

    // React.useEffect(() => {
    //   setButtonText(secondPath);
    // })

    // const getAllTheYears = () => {
    //     const theYears = data.allWpRelease.edges.map(dateYear => {
    //         const justYear = dateYear.node.date 

    //         return justYear
    //     })

    //     const uniqueYears = new Set(theYears);

    //     const uniqueArray = [...uniqueYears];

    //     return uniqueArray
    // }

    // const newYearArray = getAllTheYears();

    const [buttonText, setButtonText] = React.useState(" ");

    React.useEffect(() => {
      setButtonText(secondPath);
    }, [setButtonText, secondPath])



    return (
        <Layout>
             {/* <Seo title={metaTitle} description={recordMetaDesc} /> */}

                <PageWrapper scrollData={data.allWpArtist.nodes}>

                {/* <PageTitle>All Releases</PageTitle> */}
                <PageTitle className={`md:!-mb-10`}>
                  {buttonText ? secondPath + ' Releases' : 'All Releases'}
                </PageTitle>

                  <YearFilters dropZone={`left`} className={`lg:absolute lg:top-3 lg:left-auto lg:right-0`} buttonText={buttonText} setButtonText={setButtonText}  />

                  {/* <RecordsFeed recordData={data.allWpRelease.edges} /> */}
                  <RecordsFeed.List data={data.allWpRelease.edges}  useInitial={true} />

                  <Pagination context={pageContext} />
    

                </PageWrapper>
        </Layout>
    )
}

export const Head = ( { data, location } ) => {

    const theSlug = location.pathname
    const secondPath = theSlug.split('/')[2]
    const metaTitle = `${secondPath} Releases`;

    const recordMetaDesc = data.wp.seo.contentTypes.release.metaDesc;

  return (
    <Seo2 title={metaTitle} description={recordMetaDesc} />
  )
}

export default YearTemplate

export const query = graphql`
  query($date: Date!, $future: Date!, $skip: Int!, $limit: Int!) {
    allWpRelease(
      skip: $skip
      limit: $limit
      sort: {
        fields: date
        order: DESC
    }
    filter: {date: {gte: $date, lt: $future}}

  ) {
    edges {
      node {
        id
        title
        slug
        date
        ltaRecordsSettings {
          recordArtistSelection {
            ... on WpArtist {
              id
              title
              slug
            }
          }
          recordPreorderLink
          recordPurchaseLink
          recordStreamLink
          releaseDate
          lpNumber
        }
        imprints {
            nodes {
                name
                id
                slug
            }
        }
        featuredImage {
          node {
            localFile {
              childImageSharp {
                gatsbyImageData(
                    layout: FULL_WIDTH
                    aspectRatio: 1
                )
              }
            }
          }
        }
      }
    }

    nodes {
        title
        id
        date(formatString: "Y")
        ltaRecordsSettings {
          releaseDate
        }
    }
  }
  
  allWpArtist(
      filter: {bioFeaturedVideos: {isLtaRecordsArtist: {eq: true}}, status: {eq: "publish"}}
      sort: {fields: slug, order: ASC}
      ) {
    nodes {
        slug
        title
        bioFeaturedVideos {
            isLtaRecordsArtist
          }
      }
  }

  wp {
    seo {
      contentTypes {
        release {
          metaDesc
        }
      }
    }
  }

}
`;